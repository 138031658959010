import { Fragment } from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import DefaultHeader from "../components/DefaultHeader"
import Section from "../components/Section"
import Background from "../components/Background"
import { centerHorizontally } from "../styles/constants"
import { Heading } from "rebass/styled-components"
import Footer from "../components/Footer"
import * as React from "react"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found"/>
    <DefaultHeader/>
    <div>
      <Section.Container id="notFound" Background={Background}>
        <Fragment>
          <div style={centerHorizontally}>
            <Heading
              as="h1"
              color="primary"
              fontSize={[4, 5]}
              mb={[3, 4, 5]}
              textAlign="center"
            >
              NOT FOUND
            </Heading>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </div>
        </Fragment>
      </Section.Container>
    </div>
    <Footer/>
  </Layout>
)

export default NotFoundPage
